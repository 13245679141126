import { Component, Injectable } from '@angular/core';
import { get, set, remove } from 'src/app/services/storage';


@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public priv = this.getPriv();
  public prive: string;
  public id = this.getID();
  public user = this.getUser();
  public usuario_nome: any;
  public usuario_funcao: any;

  public appPagesUser = [
    { title: 'Relatórios', url: 'home', icon: 'home' },
    { title: 'Novo Relatório', url: 'novorel', icon: 'add-circle' },
    { title: 'Clientes', url: 'clientes', icon: 'briefcase' },
    { title: 'Meus Dados', url: 'perfil', icon: 'people' },
    { title: 'Info', url: 'sobre', icon: 'information-circle' }
  ];

  public appPagesCli = [
    { title: 'Relatórios', url: 'home', icon: 'home' },
    { title: 'Meus Dados', url: 'clientes-edit', icon: 'people' },
    { title: 'Info', url: 'sobre', icon: 'information-circle' }
  ];

  public URL_REST = 'https://masterpress.com.br/transquimi/transquimi.php?tipo=';
  //teste=true&

  public URL_UPLOAD = 'https://masterpress.com.br/transquimi/uploads/';

  public URL_APP = 'https://dangerous.masterpress.com.br/';


  constructor(
    
  ) { 

  }

  getPriv() {
    get('priv').then((data) => {
      this.prive = data;
      console.log(this.priv);
      return this.prive;
    });
  }

  getID() {
    get('id').then((data) => {
      this.id = data;
      //console.log(this.id);
      return this.id;
    });
  }

  getUser(){
    get('nome_completo').then((data) => {
      this.usuario_nome = data;
      //console.log(this.usuario_nome);
    });

    get('funcao').then((data) => {
      this.usuario_funcao = data;
      //console.log(this.usuario_funcao);
    }); 
  }

}
