import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'novorel',
    loadChildren: () => import('./pages/novorel/novorel.module').then( m => m.NovorelPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'clientes',
    loadChildren: () => import('./pages/clientes/clientes.module').then( m => m.ClientesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'view',
    loadChildren: () => import('./pages/view/view.module').then( m => m.InserirPageModule)
    },
  {
    path: 'edit',
    loadChildren: () => import('./pages/edit/edit.module').then( m => m.EditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'clientes-edit',
    loadChildren: () => import('./pages/clientes-edit/clientes-edit.module').then( m => m.ClientesEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'capturado',
    loadChildren: () => import('./pages/capturado/capturado.module').then( m => m.CapturadoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'clientes-add',
    loadChildren: () => import('./pages/clientes-add/clientes-add.module').then( m => m.ClientesAddPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'perfil-client',
    loadChildren: () => import('./pages/perfil-client/perfil-client.module').then( m => m.PerfilClientPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sobre',
    loadChildren: () => import('./pages/sobre/sobre.module').then( m => m.SobrePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
