import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
//import { AuthService } from '../services/auth.service';
import { get, set, remove } from 'src/app/services/storage';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isLoggedIn = false;
  public token: any;

  constructor(
    
    public router: Router

    ) {}


  canActivate() : Promise<boolean> {
    return new Promise(resolve => {

    //this.authService.getToken();
    return get('usuario').then((data) => {
      this.token = data;
      //console.log(this.token);
      if (this.token !== null) {
          this.isLoggedIn = true;
          //console.log('aqui logado!');
      } else {
          this.isLoggedIn = false;
          //console.log('aqui deslogado!');
      }
    
    const currentUser = this.isLoggedIn;
    console.log(this.isLoggedIn);

    if (currentUser) {
      // authorised so return true
      //console.log('logado!');
      resolve(true);
    } else {
      //console.log('nao logado!');
      // not logged in so redirect to login page with the return url
      this.router.navigate(['login']);
      resolve(false);
    }

    });
    
    });
  }

  
}
